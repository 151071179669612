@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 62%;
  font-size: 22px;
  line-height: 1.5;
}
.home {
  width: 100%;
  height: 1800px;
  background-image: url('./Components/Assets/orange.jpg');
  background-position: center top;
  background-size: cover;
}
.myP {
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 22px;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 1600px; /* Justera höjden efter behov */
  overflow: hidden;
  padding: 3em;
  margin: auto;
}
.carouselInner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 30px;
  box-shadow: 2px 2px black;
}

.nicke {
  border-radius: 30px;
  box-shadow: 1px 1px 5px black;
  width: 70%;
  margin: auto;
  display: block;
}
.contact_container {
  display: grid;
  justify-content: center;
  grid-template-columns: 50%;
  align-items: center;
}

.contact_options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

h2 {
  text-align: center;
}
footer > a {
  text-align: center;
  padding: 1rem;
}
ul {
  padding-left: 10%;
}
ul > li {
  font-weight: bold;
  text-align: left;
  list-style: disc;
}

button {
  margin: auto;
  display: block;
  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

button::before {
  content: '';
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #cecbf9 0%, #090122 100%);
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

button:hover::before {
  width: 9em;
}
.instagram-text {
  color: white;
}
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem;
}
input,
textarea {
  text-align: center;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  resize: none;
  background-color: white;
}
.svg-logo {
  width: 600px;
  padding-top: 38%;
  margin: auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

footer {
  padding: 1em;
  background-color: black;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 750px; /* Justera höjden efter behov */
    overflow: hidden;
    padding: 3em;
    margin: auto;
  }

  .svg-logo {
    width: 600px;
    padding-top: 45%;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1rem;
  }

  input,
  textarea {
    text-align: center;
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    resize: none;
    background-color: white;
  }
}
@media screen and (max-width: 768px) {
  .svg-logo {
    height: 200px;
    width: 300px;
    padding-top: 34%;
    left: 0;
    right: 0;
  }
  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 500px; /* Justera höjden efter behov */
    overflow: hidden;
    padding: 3em;
    margin: auto;
  }

  .home {
    height: 750px;
  }

  .contact_container {
    padding: 2em;
    display: grid;
    grid-template-columns: 90%;
  }

  .carousel {
    padding: 3em;
    height: 100vh;
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1rem;
    align-items: center;
  }

  .carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 500px; /* Justera höjden efter behov */
    overflow: hidden;
    padding: 3em;
    margin: auto;
  }
  .carouselInner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;
    box-shadow: 2px 2px black;
  }

  .myP {
    text-align: justify;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.3em;
  }
}
@media all and (max-width: 700px) {
  .myP {
    padding: 4%;
    text-align: center;
    font-size: 1.3em;
  }
  p {
    font-size: 0.8em;
  }
}
